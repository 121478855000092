(() => {
  const init = () => {
    const supportsHasPseudoClass = () => {
      try {
        document.querySelector(':has(*)');
        return true;
      } catch (e) {
        return false;
      }
    };

    const handleHasSupports = () => {
      const mapper = ['u-mx-auto', 'u-right-align', 'u-center-align'];

      for (const key of mapper) {
        const $els = document.querySelectorAll(`.${key}`);
        $els.forEach($el => {
          const $parent = $el.parentElement as HTMLElement;
          $parent?.classList.add(`js-has-${key}`);
        });
      }
    };

    // when supports pseudo selector is not supported
    // add corresponding classes to the parent elements to add appropriate styles
    if (supportsHasPseudoClass() !== true) {
      handleHasSupports();
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
