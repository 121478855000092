(() => {
  const handleCarousels = () => {
    let $carouselEls: NodeListOf<HTMLElement>,
      $nonLoopedCarouseEls: HTMLElement[];

    // center aligns the content of the carousel when the combined content width of all the slides is less than the actual carousel
    const centerAlignCarouselContent = $el => {
      const $content = $el.querySelector(
        '.emu-carousel__content'
      ) as HTMLElement;

      if ($content) {
        if ($content.clientWidth < $el.clientWidth) {
          $content.classList.add('u-center-align');
          const $slideItems = $content.querySelectorAll(
            '.tns-item'
          ) as NodeListOf<HTMLElement>;
          if ($slideItems?.length > 1) {
            const gutter = parseInt(
              window.getComputedStyle($slideItems[0]).paddingRight,
              10
            );
            if (gutter && isNaN(gutter) !== true) {
              $content.style.marginLeft = `${
                gutter / ($slideItems.length - 1)
              }px`;
            }
          }
        } else {
          $content.classList.remove('u-center-align');
          $content.style.marginLeft = '';
        }
      }
    };

    const centerAlignNonLoopedCarousels = () => {
      if ($nonLoopedCarouseEls?.length) {
        $nonLoopedCarouseEls.forEach($el => {
          centerAlignCarouselContent($el);
        });
      }
    };

    const appendEvents = () => {
      window.addEventListener('resize', () => {
        centerAlignNonLoopedCarousels();
      });
    };

    const initVariables = () => {
      $carouselEls = document.querySelectorAll(
        '.emu-carousel'
      ) as NodeListOf<HTMLElement>;
      if ($carouselEls.length) {
        $nonLoopedCarouseEls = Array.from($carouselEls)?.filter(
          $el => $el.getAttribute('data-loop') === 'false'
        );
      }
    };

    const init = () => {
      centerAlignNonLoopedCarousels();
    };

    initVariables();
    appendEvents();
    init();
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleCarousels);
  } else {
    handleCarousels();
  }
})();
