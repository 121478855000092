(() => {
  const handleHeaderMenu = () => {
    let $bodyEl: HTMLBodyElement,
      $headerEl: HTMLElement,
      prevScrollPos = window.scrollY,
      $headerDropdownLinks: NodeListOf<HTMLLIElement>,
      $headerTopBar: HTMLElement,
      $headerNavContainer: HTMLElement,
      $headerNavCtaContainer: HTMLElement,
      desktopMq = window.matchMedia('(min-width: 1024px)'),
      headerHeight = 0,
      $remediationBanner: HTMLElement,
      $remediationBannerClose: HTMLButtonElement;

    // set header height and other css variables related to the elements inside the header
    const updateHeaderCSSVariables = () => {
      const headerTopBarHeight = $headerTopBar?.offsetHeight || 0;
      const findAProviderHeight = $headerNavCtaContainer?.scrollHeight || 0;
      const remediationBannerHeight = $remediationBanner?.offsetHeight || 0;
      const root = document.documentElement;
      headerHeight = $headerEl?.offsetHeight || 0;

      headerHeight += remediationBannerHeight;
      root.style.setProperty('--headerHeight', `${headerHeight}px`);
      root.style.setProperty(
        '--findAProviderCTAHeight',
        `${$headerNavCtaContainer ? Math.max(findAProviderHeight, 75) : 0}px`
      ); // 75px is the minimum height needed
      root.style.setProperty('--topBarHeight', `${headerTopBarHeight}px`);
      root.style.setProperty('--bannerHeight', `${remediationBannerHeight}px`);
    };

    // handles all the window resize events
    const handleWindowResize = () => {
      updateHeaderCSSVariables();
    };

    // scroll event
    const handleWindowScroll = () => {
      const curScrollPos = window.scrollY;

      // when scroll position is greater than header height, make the header hidden
      // CSS for these classes will take care of the visibility of the header and its elements
      $bodyEl?.classList.toggle(
        'u-page--scrolled',
        curScrollPos > headerHeight
      );

      // when page is scrolled towards the top by 40px, show the header, without the black top bar
      // CSS for these classes will take care of the visibility of the header and its elements
      $bodyEl?.classList.toggle(
        'u-page--scrolled-down',
        curScrollPos > prevScrollPos
      );

      if (prevScrollPos - curScrollPos > 40) {
        $bodyEl?.classList.add('u-page--scrolled-up-40');
      }

      // when scroll position is lesser than the header height, show the header
      // CSS for these classes will take care of the visibility of the header and its elements
      if (scrollY < headerHeight) {
        $bodyEl?.classList.remove(
          'u-page--scrolled-up-40',
          'u-page--scrolled-down',
          'u-page--scrolled-up'
        );
      }

      prevScrollPos = curScrollPos;
    };

    // initiating the variables used across this file
    const initVariables = () => {
      $headerEl = document.querySelector('header.header') as HTMLElement;
      $bodyEl = document.body as HTMLBodyElement;
      $headerDropdownLinks = $headerEl?.querySelectorAll(
        'li.emu-navigation__item-parent'
      ) as NodeListOf<HTMLLIElement>;
      $headerTopBar = $headerEl?.querySelector(
        '.header__top-bar'
      ) as HTMLElement;
      $headerNavCtaContainer = $headerEl
        .querySelector('.header__nav-cta-container')
        ?.closest('.container') as HTMLElement;
      prevScrollPos = window.scrollY;
      $headerNavContainer = $headerEl.querySelector(
        '.header__nav-container'
      ) as HTMLElement;
      $remediationBanner = document.querySelector(
        '#remediationBanner .wrapper-block'
      ) as HTMLElement;
      $remediationBannerClose = $remediationBanner?.querySelector(
        '.btn.close-icon'
      )! as HTMLButtonElement;
    };

    const appendEvents = () => {
      desktopMq.addEventListener('change', handleWindowResize);

      const throttledFn = window.AAAEM?.utilities?.throttle?.(
        handleWindowScroll,
        50
      );
      if (throttledFn) {
        window.addEventListener('scroll', throttledFn);
      }

      // when menu button is clicked, toggle a class to the body element
      //@ts-ignore
      window.Bus.on('emu-button:click', ({ id, toggleOn }) => {
        if (id === 'header-menu-open-cta') {
          // when mobile menu trigger is clicked, add/remove a class to the body
          // Using this class the menu will be transitioned from left to right
          $bodyEl.classList.toggle('u-page--mobile-menu-open', toggleOn);
          if (toggleOn) {
            // adding a class to the nav container to take care of the visibility of it
            $headerNavContainer.classList.remove(
              'header__nav-container--hidden'
            );
          }
        }
      });

      // removing a class to the nav container to take care of the visibility of it
      // ensures the open and the close animation of the mobile menu is smooth
      $headerNavContainer.addEventListener('transitionend', () => {
        if (
          !$headerNavContainer.classList.contains(
            'header__nav-container--hidden'
          ) &&
          !$bodyEl.classList.contains('u-page--mobile-menu-open')
        ) {
          $headerNavContainer.classList.remove('header__nav-container--hidden');
        }
      });

      // when a parent list item is clicked, adding/removing a class to show the sub nav
      $headerDropdownLinks.forEach(el => {
        const parentAnchorElement = el.querySelector('a');
        parentAnchorElement?.addEventListener('click', e => {
          e.preventDefault();
          el.classList.toggle('show-sub-nav');
        });
      });

      $remediationBannerClose?.addEventListener('click', () => {
        updateHeaderCSSVariables();
      });
    };

    const init = () => {
      // on page load, making sure that the mobile menu is hidden
      $headerNavContainer?.classList.add('header__nav-container--hidden');
      updateHeaderCSSVariables();
    };

    // function calls
    initVariables();
    appendEvents();
    init();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleHeaderMenu);
  } else {
    handleHeaderMenu();
  }
})();
